.header-header {
  position: relative;
  background-color: #dad2bc;
}
.header-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.header-navlink {
  display: contents;
}
.header-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}
.header-image {
  width: 75px;
  height: 78px;
  object-fit: cover;
  margin-right: 14px;
}
.header-image1 {
  width: 232px;
  height: 72px;
  object-fit: cover;
}
.header-desktop-menu {
  display: flex;
}
.header-links {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-link {
  color: #3a5a40;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink1 {
  color: #3a5a40;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink2 {
  color: #3a5a40;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink3 {
  color: #3a5a40;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.header-navlink4 {
  color: #3a5a40;
  border-color: #3a5a40;
  text-decoration: none;
  background-color: #dad2bc;
}
.header-navlink5 {
  border-color: #ffffff;
  text-decoration: none;
  background-color: #3a5a40;
}
.header-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.header-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 427px;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #dad2bc;
}
.header-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #dad2bc;
}
.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-navlink6 {
  display: contents;
}
.header-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}
.header-image2 {
  width: 75px;
  height: 78px;
  object-fit: cover;
  margin-right: 14px;
}
.header-image3 {
  width: 232px;
  height: 72px;
  object-fit: cover;
}
.header-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-link1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-link2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-link3 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-link4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.header-container1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.header-navlink7 {
  color: #3a5a40;
  border-color: #3a5a40;
  text-decoration: none;
  background-color: #dad2bc;
}
.header-navlink8 {
  border-color: #ffffff;
  text-decoration: none;
  background-color: #3a5a40;
}
.header-icon-group {
  display: flex;
}
.header-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.header-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}







@media(max-width: 1600px) {
  .header-mobile-menu {
    height: 436px;
    background-color: #dad2bc;
  }
  .header-nav {
    background-color: #dad2bc;
  }
}
@media(max-width: 1500px) {
  .header-mobile-menu {
    height: 436px;
    background-color: #dad2bc;
  }
  .header-nav {
    background-color: #dad2bc;
  }
}
@media(max-width: 991px) {
  .header-logo {
    width: 310px;
    justify-content: center;
  }
  .header-image {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .header-navlink4 {
    width: 101px;
    height: 47px;
  }
  .header-mobile-menu {
    height: 418px;
    background-color: #dad2bc;
  }
  .header-nav {
    background-color: #dad2bc;
  }
  .header-image2 {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .header-root-class-name {
    align-self: center;
  }
  .header-root-class-name1 {
    align-self: center;
  }
  .header-root-class-name2 {
    align-self: center;
  }
  .header-root-class-name3 {
    align-self: center;
  }
  .header-root-class-name4 {
    align-self: center;
  }
  .header-root-class-name5 {
    align-self: center;
  }
  .header-root-class-name6 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .header-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-desktop-menu {
    display: none;
  }
  .header-links {
    display: none;
  }
  .header-burger-menu {
    display: flex;
  }
  .header-mobile-menu {
    height: 434px;
    background-color: #dad2bc;
  }
  .header-nav {
    background-color: #dad2bc;
  }
}
@media(max-width: 479px) {
  .header-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .header-mobile-menu {
    padding: 16px;
  }
}
@media(max-width: 430px) {
  .header-links {
    display: none;
  }
  .header-mobile-menu {
    height: 430px;
    background-color: #dad2bc;
  }
  .header-nav {
    background-color: #dad2bc;
  }
}
