.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #dad2bc;
}
.home-hero {
  background-color: #3a5a40;
}
.home-max-width {
  align-items: center;
  margin-left: 16px;
  background-color: #3a5a40;
}
.home-content {
  flex: 0 0 auto;
  width: 512px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-eightunits);
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-subtitle {
  color: #dad2bc;
  display: none;
  margin-bottom: var(--dl-space-space-unit);
}
.home-title {
  color: rgb(248, 251, 250);
  width: 514px;
  font-size: 52px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-button {
  color: rgb(58, 90, 64);
  display: none;
  background-color: rgb(218, 210, 188);
}
.home-navlink {
  text-decoration: none;
}
.home-navlink1 {
  color: rgb(58, 90, 64);
  width: auto;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
  line-height: 0.9;
  margin-left: 16px;
  padding-top: 20px;
  margin-right: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 20px;
  text-decoration: none;
  background-color: rgb(218, 210, 188);
}
.home-image {
  position: relative;
  transform: rotate(-30deg);
  padding-bottom: var(--dl-space-space-eightunits);
}
.home-pasted-image {
  top: -492px;
  flex: 1;
  left: -359px;
  width: 200px;
  position: absolute;
  align-self: center;
}
.home-graphic-top {
  top: -274px;
  left: -184px;
  width: 606px;
  height: 609px;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
.home-image1 {
  top: -107px;
  left: -761px;
  width: 643px;
  height: 533px;
  position: absolute;
  object-fit: contain;
}
.home-section {
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #dad2bc;
}
.home-text16 {
  color: #3a5a40;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text17 {
  color: rgb(58, 90, 64);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-image2 {
  width: 656px;
  z-index: 10;
  user-select: none;
}
.home-max-width1 {
  align-items: stretch;
}
.home-text20 {
  color: #3a5a40;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-navlink2 {
  color: #dad2bc;
  border-color: #dad2bc;
  text-decoration: none;
  background-color: #3a5a40;
}
.home-section1 {
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #3a5a40;
}
.home-max-width2 {
  align-items: stretch;
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-image3 {
  width: 50%;
}
.home-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 48px;
}
.home-content1 {
  width: 40%;
  height: 557px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  padding-top: var(--dl-space-space-eightunits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-eightunits);
  justify-content: center;
}
.home-text29 {
  color: #dad2bc;
  font-size: 14px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.15;
  padding-top: var(--dl-space-space-eightunits);
  margin-bottom: var(--dl-space-space-unit);
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
.home-text30 {
  color: rgb(218, 210, 188);
  font-size: 42px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-container2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-step {
  display: flex;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: #dad2bc;
}
.home-text31 {
  color: #3a5a40;
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title1 {
  color: rgb(218, 210, 188);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text32 {
  color: rgb(218, 210, 188);
  width: 500px;
  line-height: 1.5;
}
.home-step1 {
  display: flex;
  position: relative;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: #dad2bc;
}
.home-text33 {
  color: #3a5a40;
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container4 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title2 {
  color: #dad2bc;
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text34 {
  color: rgb(218, 210, 188);
  width: 500px;
  line-height: 1.5;
}
.home-step2 {
  display: flex;
  flex-direction: row;
}
.home-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: #dad2bc;
}
.home-text35 {
  color: #3a5a40;
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title3 {
  color: rgb(218, 210, 188);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text36 {
  color: rgb(218, 210, 188);
  width: 500px;
  line-height: 1.5;
}
.home-step3 {
  display: flex;
  flex-direction: row;
}
.home-number3 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: #dad2bc;
}
.home-text37 {
  color: rgb(58, 90, 64);
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
.home-container6 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-title4 {
  color: rgb(218, 210, 188);
  font-size: 24px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text38 {
  color: rgb(218, 210, 188);
  width: 500px;
  line-height: 1.5;
}
.home-section2 {
  padding-top: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #dad2bc;
}
.home-text39 {
  color: #3a5a40;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text40 {
  color: rgb(58, 90, 64);
  font-size: 40px;
  font-style: normal;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-max-width3 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-navlink3 {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-navlink4 {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-navlink5 {
  display: contents;
}
.home-component3 {
  text-decoration: none;
}
@media(max-width: 1500px) {
  .home-hero {
    height: 979px;
    padding-top: 0px;
    padding-bottom: 2px;
  }
}
@media(max-width: 991px) {
  .home-content {
    width: 50%;
    margin-right: var(--dl-space-space-unit);
  }
  .home-image {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-pasted-image {
    top: -448px;
    left: -272px;
    width: 200px;
  }
  .home-graphic-top {
    top: -316px;
    left: -162px;
  }
  .home-image1 {
    top: -103px;
    left: -678px;
  }
  .home-text17 {
    text-align: center;
  }
  .home-image2 {
    width: 100%;
  }
  .home-max-width1 {
    flex-direction: column;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-image3 {
    width: 100%;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-hero-image {
    width: 100%;
    height: 687px;
    margin-left: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-content1 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .home-text29 {
    padding-top: var(--dl-space-space-eightunits);
  }
  .home-container6 {
    height: 291px;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-text38 {
    color: rgb(218, 210, 188);
    line-height: 1.5;
    padding-bottom: var(--dl-space-space-eightunits);
  }
  .home-section2 {
    height: 702px;
  }
  .home-max-width3 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-max-width {
    flex-direction: column-reverse;
  }
  .home-content {
    width: 100%;
    height: 518px;
    padding-top: 0px;
    margin-right: 0px;
  }
  .home-title {
    height: 367px;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-bottom: 0px;
  }
  .home-pasted-image {
    top: 166px;
    left: 19px;
    width: 175px;
    height: 357px;
  }
  .home-graphic-top {
    left: 89px;
    bottom: -916px;
  }
  .home-image1 {
    left: 239px;
    width: 367px;
    bottom: -598px;
    height: 156px;
  }
  .home-image2 {
    width: 100%;
  }
  .home-hero-image {
    height: 519px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-content1 {
    width: 100%;
    margin-right: 0px;
  }
  .home-section2 {
    height: 1695px;
  }
  .home-cards-container {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media(max-width: 430px) {
  .home-hero {
    height: 878px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-title {
    color: rgb(248, 251, 250);
    width: 401px;
    height: 533px;
    font-size: 52px;
    font-style: normal;
    font-family: Urbanist;
    font-weight: 600;
    line-height: 1.2;
  }
  .home-text {
    color: #dad2bc;
  }
  .home-text03 {
    color: #feffff;
  }
  .home-text05 {
    color: #dad2bc;
  }
  .home-text08 {
    color: #ffffff;
  }
  .home-text10 {
    color: #dad2bc;
  }
  .home-text13 {
    color: #ffffff;
  }
  .home-text15 {
    color: #dad2bc;
  }
  .home-description {
    color: var(--dl-color-scheme-white);
    font-size: 18px;
    line-height: 1.5;
    padding-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-pasted-image {
    left: 220px;
    width: 150px;
    bottom: -876px;
  }
  .home-section {
    height: 1170px;
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: 0px;
  }
  .home-text17 {
    color: rgb(58, 90, 64);
    font-size: 42px;
    font-style: normal;
    font-family: Urbanist;
    font-weight: 700;
    line-height: 1.25;
  }
  .home-max-width1 {
    height: 631px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text20 {
    color: rgb(58, 90, 64);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    line-height: 1.5;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-section1 {
    width: 100%;
    height: 1530px;
    padding-top: 0px;
    margin-right: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .home-max-width2 {
    height: 1503px;
    margin-bottom: 282px;
  }
  .home-image3 {
    height: 231px;
    padding-bottom: 0px;
  }
  .home-hero-image {
    height: 411px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .home-content1 {
    height: 714px;
  }
  .home-text29 {
    margin-top: var(--dl-space-space-sixunits);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-text30 {
    height: 119px;
  }
  .home-container3 {
    width: 243px;
    height: 274px;
  }
  .home-text32 {
    color: rgb(218, 210, 188);
    width: auto;
    align-self: stretch;
    line-height: 1.5;
  }
  .home-text34 {
    width: auto;
  }
  .home-text36 {
    width: auto;
  }
  .home-step3 {
    height: 170px;
  }
  .home-container6 {
    height: 165px;
    margin-bottom: var(--dl-space-space-eightunits);
    padding-bottom: 0px;
  }
  .home-text38 {
    width: auto;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .home-section2 {
    height: 1750px;
    position: relative;
    margin-top: 0px;
    align-items: flex-start;
    padding-top: 0px;
    margin-bottom: 0px;
    flex-direction: column;
    padding-bottom: 0px;
  }
  .home-text39 {
    top: 53px;
    left: 153px;
    position: absolute;
  }
  .home-text40 {
    color: rgb(58, 90, 64);
    height: 144px;
    font-size: 40px;
    font-style: normal;
    margin-top: var(--dl-space-space-fiveunits);
    font-family: Urbanist;
    font-weight: 700;
    line-height: 1.25;
    padding-top: 0px;
    margin-bottom: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-max-width3 {
    height: 982px;
    margin-top: var(--dl-space-space-eightunits);
    margin-bottom: 180px;
  }
}
