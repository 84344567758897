.blog-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
@media(max-width: 1500px) {
  .blog-container {
    background-color: #dad2bc;
  }
}
